import Swiper from 'swiper/bundle';

window.addEventListener('DOMContentLoaded', () => {
    initSwiper();
})

function initSwiper() {
    let livingSwiper = new Swiper('.living-swiper', {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        observeParents: true,
        observe: true,
        // centeredSlides: true,
        // initialSlide: 1,   //start at second slide
        allowTouchMove: false,
        navigation: {
            nextEl: '.arrow.next',
            prevEl: '.arrow.prev',
        },
        // Responsive breakpoints
        breakpoints: {
            // // when window width is >= 568px
            575: {
                slidesPerView: 1.3,
                slidesPerGroup: 1,
                spaceBetween: 20,
            },
            // // when window width is >= 768px
            768: {
                slidesPerView: 2.3,
                slidesPerGroup: 2,
                spaceBetween: 20,
            },
            // // when window width is >= 992px
            // 992: {
            //     slidesPerView: 2.2,
            //     slidesPerGroup: 2,
            //     spaceBetween: 20,
            // },
            // // when window width is >= 1200px
            // 1200: {
            //     slidesPerView: 2.2,
            //     slidesPerGroup: 2,
            //     spaceBetween: 20,
            // }
        },
        // loop: true,
        on: {
            'init': () => {
                // document.querySelector('.gallery-desc').textContent = document.querySelector('.swiper-slide-active').getAttribute('data-desc');
            },
            resize: () => {
                livingSwiper.update();
            },
            slideChange: () => {
                // if(!livingSwiper) return;
            }
        }
    })

    let galleryThumbSwiper = new Swiper('.gallery-thumb-swiper', {
        // slidesPerView: 4,
        observeParents: true,
        observe: true,
        spaceBetween: 10,
        mousewheel: {
            invert: false,
        },
        slidesPerView: 4,
        slidesPerGroup: 1,
        // watchOverflow: true,
        autoHeight: true,
        breakpoints: {
            // when window width is >= 568px
            // 575: {
            // },
            // // when window width is >= 768px
            // 768: {
            // },
            // when window width is >= 992px
            // 992: {
            // },
            // when window width is >= 1200px
            1200: {
                direction: 'vertical',
                spaceBetween: 10,
                autoHeight: true
            }
        },
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        // direction: 'vertical',
        on: {
            'init': () => {
                // document.querySelector('.gallery-desc').textContent = document.querySelector('.swiper-slide-active').getAttribute('data-desc');
                console.log('gallery swiper init')
            },
            resize: () => {
                setTimeout(()=>{
                    galleryThumbSwiper.update();
                },300)
            },
            slideChange: () => {
                // if(!livingSwiper) return;

                // let galleryDescDiv = document.querySelector('.gallery-desc');
                // let newDesc = livingSwiper.slides[livingSwiper.activeIndex].getAttribute('data-desc');

                // galleryDescDiv.textContent = newDesc;
            }
        }
    })

    let gallerySwiper = new Swiper('.gallery-swiper', {
        slidesPerView: 1,
        observeParents: true,
        observe: true,
        spaceBetween: 0,
        parallax: true,
        navigation: {
            nextEl: '.gallery-swiper .gallery-next',
            prevEl: '.gallery-swiper .gallery-prev',
        },
        // allowTouchMove: false,
        // navigation: {
        //     nextEl: '.arrow.next',
        //     prevEl: '.arrow.prev',
        // },
        thumbs: {
            swiper: galleryThumbSwiper,
        },
        // effect: 'fade',
        // fadeEffect: {
        //     // crossFade: true
        // },
        speed: 1500,
        // Responsive breakpoints
        breakpoints: {
            // // when window width is >= 568px
            // 575: {
            //     slidesPerView: 1.3,
            //     slidesPerGroup: 1,
            //     spaceBetween: 20,
            // },
            // // // when window width is >= 768px
            // 768: {
            //     slidesPerView: 2.3,
            //     slidesPerGroup: 2,
            //     spaceBetween: 20,
            // },
            // // when window width is >= 992px
            // 992: {
            //     slidesPerView: 2.2,
            //     slidesPerGroup: 2,
            //     spaceBetween: 20,
            // },
            // // when window width is >= 1200px
            // 1200: {
            //     slidesPerView: 2.2,
            //     slidesPerGroup: 2,
            //     spaceBetween: 20,
            // }
        },
        // loop: true,
        on: {
            'init': () => {
                // document.querySelector('.gallery-desc').textContent = document.querySelector('.swiper-slide-active').getAttribute('data-desc');
                console.log('gallery swiper init')
            },
            resize: () => {
                gallerySwiper.update();
            },
            slideChange: () => {
                // if(!gallerySwiper) return;

                console.log('dd');

                let galleryDescDiv = document.querySelector('.gallery-desc span');
                galleryDescDiv.parentNode.classList.add('blur');
                let newDesc = gallerySwiper.slides[gallerySwiper.activeIndex].dataset.desc;
                
                setTimeout(()=>{
                    galleryDescDiv.textContent = newDesc;
                    galleryDescDiv.parentNode.classList.remove('blur');
                },1500)
            }
        }
    })
}