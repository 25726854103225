import Panzoom from '@panzoom/panzoom';

window.addEventListener('DOMContentLoaded', ()=> {
    document.querySelector('.magnifier .mag-active').addEventListener('click', (e) => {
        let target = e.currentTarget.parentNode.getAttribute('data-target');
        
        const panzoom_masterplan = Panzoom(document.querySelector(target), { contain: 'outside', startScale: 2.5, duration: 500 });

        e.currentTarget.parentNode.classList.add('active');

        document.querySelector('.magnifier .mag-inactive').addEventListener('click', (e)=>{
            e.currentTarget.parentNode.classList.remove('active');
            let elem_masterplan = document.querySelector('.pan-section');
            setTimeout(()=>{
                elem_masterplan.style.transform = "scale(1)";
                elem_masterplan.style.cursor = "inherit";
                elem_masterplan.style.userSelect = "inherit";
                elem_masterplan.style.touchAction = "inherit";
                elem_masterplan.parentElement.style.userSelect = "inherit";
                elem_masterplan.parentElement.style.touchAction = "inherit";
                panzoom_masterplan.destroy();
            },100)
        })
    })
});