/**
 * Pavilion Genting Highlands
 * @author FOREFRONT International <support@forefront.com.my>
 */
import $ from 'jquery';
import 'bootstrap';
import 'slick-carousel';
import MoveTo from 'moveto';
import ScrollMonitor from 'scrollmonitor';
import * as Utils from './utils';
import Foresight from './vendor/foresight';

import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

import './utils/swiper';

import './zoom';

import box_data from './const/box-data';

let foresight = null;

//smoothScroll
import './utils/smoothScroll';

// Run entry function when DOM has fully loaded
window.addEventListener('DOMContentLoaded', main);

let init = false;

// Run loader animation
if (Utils.isPage('home')) {
  setTimeout(()=> {
    window.scrollTo(0, 0);
  },800)

  // document.querySelector('.video-bg video').addEventListener('progress',()=>{
  //   console.warn('downloading...');
  // })
  // document.querySelector('.video-bg video').addEventListener('play',()=>{
    // console.warn('downloading done')
    
    // if(!init)
    window.setTimeout(enableLoadingAnimation, 400); init = true;
  // })
}


/**
 * Entry function.
 */
function main() {
  // Run global functions
  enableSmoothAnchorScrolling();
  enableMobileNavigation();
  enableLanguage();
  enableEventTracking();

  // Run homepage functions
  if (Utils.isPage('home')) {
    enableFixedNavigation();
    enableFeaturesCarousel();
    trackFormAbandonment();
  }

  // ids of stroke
  const stroke = {
    resort_world_genting: [
      'resort_world_genting_1',
      'resort_world_genting_2'
    ],
    awana_skyway: [
      'awana_skyway_1',
      'awana_skyway_2'
    ],
    gotong_jaya: [
      'gotong_jaya'
    ],
    kl_tower: [
      'kl_tower_1',
      'kl_tower_2'
    ],
    pavilion_kl: [
      'pavilion_kl_1',
      'pavilion_kl_2'
    ],
    klia: [
      'klia_1',
      'klia_2'
    ]
  };

  const stroke_value = {
    resort_world_genting_1: 428.5,
    resort_world_genting_2: 114.615,
    awana_skyway_1: 135.182,
    awana_skyway_2: 114.615,
    gotong_jaya: 119.408,
    kl_tower_1: 252.711,
    kl_tower_2: 132.93,
    pavilion_kl_1: 289.002,
    pavilion_kl_2: 132.93,
    klia_1: 214.335,
    klia_2: 132.92
  }


  //initial the first road path

  let timeline = gsap.timeline();
  stroke.resort_world_genting.forEach(item => {
    let id = '#' + item;

    timeline.to($(id), {
      strokeDashoffset: `${stroke_value[item] * 2}`,
      duration: 2,
      // onStart: ()=>{
      //   $(id).addClass('active')
      // }
    }, '>');
  })

  document.querySelectorAll('.dot').forEach(ele => {
    ele.addEventListener('click', () => {
      clearDotActive();

      ele.classList.add('active');

      var tl = gsap.timeline();

      $('#' + ele.dataset.target).removeClass('hidden');

      // let mq = window.matchMedia("(min-width: 767px)");
      let mq = deviceWidth(767);


      if (mq) {
        setTimeout(() => {
          stroke[ele.dataset.target].forEach((item, i) => {
            let id = '#' + item;

            if (i == 0) {
              tl.to($(id), {
                strokeDashoffset: `${stroke_value[item] * 2}`,
                duration: 2,
                // onStart: ()=>{
                //   $(id).addClass('active')
                // }
              });
            } else {
              tl.to($(id), {
                strokeDashoffset: `${stroke_value[item] * 2}`,
                duration: 2,
                // onStart: ()=>{
                //   $(id).addClass('active')
                // }
              }, '+=.001');
            }
          })
        }, 200);
      } else {
        gsap.to(window, {
          scrollTo: {
            y: '.location-map-container',
            offsetY: 50,
          },
          duration: .6,
          onComplete: () => {
            setTimeout(() => {
              stroke[ele.dataset.target].forEach((item, i) => {
                let id = '#' + item;

                if (i == 0) {
                  tl.to($(id), {
                    strokeDashoffset: `${stroke_value[item] * 2}`,
                    duration: 2,
                    // onStart: ()=>{
                    //   $(id).addClass('active')
                    // }
                  });
                } else {
                  tl.to($(id), {
                    strokeDashoffset: `${stroke_value[item] * 2}`,
                    duration: 2,
                    // onStart: ()=>{
                    //   $(id).addClass('active')
                    // }
                  }, '+=.001');
                }
              })
            }, 1000);
          }
        })
      }
    })
  })

  function clearDotActive() {
    document.querySelectorAll('.dot').forEach(ele => {
      ele.classList.remove('active');
    })

    $('.stroke').addClass('hidden');

    Object.keys(stroke).forEach(key => {
      stroke[key].forEach(item => {
        // document.querySelector('#' + item).classList.remove('active');
        document.querySelector('#' + item).style.strokeDashoffset = null;
      })
    })
  }

  document.querySelectorAll('.navbar-nav li a').forEach(ele => {
    ele.addEventListener('click', () => {
      // document.querySelector('.navbar').classList.remove('open');
      document.getElementById('nav-icon').classList.remove('open');
      $(".navi-contain").removeClass('is-active');
      gsap.to(window, {
        duration: 1,
        scrollTo: ele.getAttribute('data-target'),
        onComplete: () => {
        }
      });
    })
  })

  const tl = gsap.timeline({ defaults: { ease: "expo.inOut" }, paused: true })
    .to('.box-inner', {
      y: '-10',
      autoAlpha: 0,
      duration: .3
    })
    .to('.box-bg', {
      x: '110%',
      duration: .5
    })

  //populate content of box
  document.querySelectorAll('.box-inner-content').forEach((ele, index) => {
    let box_index = "box_" + (index + 1);

    let div_title = document.createElement('div');
    div_title.classList.add('overbox-tag');
    div_title.classList.add('my-3');
    div_title.classList.add('text-uppercase');
    div_title.innerHTML = box_data[box_index].tag;

    ele.appendChild(div_title);

    let ul = document.createElement('ul');
    ul.classList.add('overbox-points');
    ul.classList.add('text-left');
    ul.classList.add('pl-3');

    box_data[box_index].points.forEach(item => {
      let li = document.createElement('li');
      li.innerHTML = item;

      ul.appendChild(li);
    })

    ele.appendChild(ul);
  })
  document.querySelectorAll('.box-inner-find-btn').forEach(ele => {
    ele.addEventListener('click', (e) => {
      let bg = ele.dataset.bg;

      document.querySelector('.box-content-overbox').style.backgroundImage = `url(${bg})`;

      let box = "box_" + ele.dataset.box;

      document.querySelector('.overbox-tagline').textContent = box_data[box].tagline;
      document.querySelector('.overbox-tag.overlay').textContent = box_data[box].tag;

      document.querySelector('.overbox-points.overlay').innerHTML = "";

      box_data[box].points.forEach(item => {
        let li = document.createElement('li');
        li.textContent = item;

        document.querySelector('.overbox-points.overlay').append(li);
      })

      gsap.to(window, {
        duration: 1,
        scrollTo: '.concept-section',
        ease: 'expo.inOut',
        onComplete: () => {
          tl.play();

          tl.eventCallback('onComplete', () => {
            document.querySelector('.box-container').classList.add('is-open');
          })
        }
      });
    })
  })

  document.querySelector('.overbox-back-button').addEventListener('click', (e) => {
    document.querySelector('.box-container').classList.remove('is-open');

    gsap.to(window, {
      duration: 1,
      scrollTo: '.concept-section',
      ease: 'expo.inOut',
      onComplete: () => {
        tl.reverse();
      }
    });

    if (tl.reversed) {
      document.querySelectorAll('.box-bg').forEach(ele => {
        setTimeout(() => {
          ele.style.transform = null;
        }, 1300)
      })
    }

  })

  document.querySelectorAll('.box-inner-see-btn').forEach(ele => {
    ele.addEventListener('click', () => {
      const content = ele.previousElementSibling;

      // console.log(content)

      const contentHeight = content.getBoundingClientRect().height
      content.dataset.height = contentHeight

      if (content.classList.contains('is-open')) {
        ele.innerHTML = "see more";
        gsap.to(content, { duration: 0.3, height: 0, autoAlpha: 0, clearProps: "all", onComplete: () => { content.classList.remove('is-open') } })
      } else {
        content.classList.add('is-open')

        ele.innerHTML = "see less";

        const contentHeight = content.getBoundingClientRect().height
        content.dataset.height = contentHeight
        gsap.set(content, { height: 0 })
        gsap.to(content, { duration: 0.3, height: contentHeight, autoAlpha: 1, clearProps: "all" })
      }
    })
  })

  adjustFooterSocial();
  adjustContentTop();

  window.addEventListener('resize', () => {
    adjustFooterSocial();
    adjustContentTop();
    socialiconmonitor();
  });

  backtopmonitor();

  window.addEventListener('scroll', backtopmonitor);


  gsap.to("nav", {
    scrollTrigger: {
      trigger: "section:nth-of-type(2)",
      // markers: true,
      start: "top top", // the default values
      end: "bottom top",
      toggleActions: "play none none reverse",
    }, // start the animation when ".box" enters the viewport (once)
    y: '0%',
    autoAlpha: 1,
    duration: .5
  });

  // gsap.to("nav", {
  //   scrollTrigger: {
  //     trigger: "section:nth-of-type(1)",
  //     markers: true,
  //     start: "bottom top", // the default values
  //     end: "top top",
  //   }, // start the animation when ".box" enters the viewport (once)
  //   y: '-100%',
  //   autoAlpha: 0,
  //   duration: .5,
  // });

  function adjustFooterSocial() {
    let mq = deviceWidth(1200);

    if (mq) {
      // console.log("window width >= 1200px");
      document.querySelector('footer').style.paddingBottom = null;
      document.querySelector('.back-to-top').style.bottom = null;
    } else {
      // console.log("window width < 1200px");
      document.querySelector('footer').style.paddingBottom = `${document.querySelector('.social-container').clientHeight + (16 * 3)}px`;

      document.querySelector('.back-to-top').style.bottom = `${document.querySelector('.social-container').clientHeight + (16 * 4)}px`;
    }
  }

  function adjustContentTop() {
    // let mq = window.matchMedia("(min-width: 767px)");
    let mq = deviceWidth(767);

    let nav_height = document.querySelector('nav').clientHeight;

    let box_inner = document.querySelectorAll('.box-inner');

    let masterplan_inner = document.querySelector('.masterplan-inner-container');

    // console.log(mq.matches);
    box_inner.forEach(ele => {
      if (mq) {
        // console.log("window width >= 1200px");
        ele.style.paddingTop = `${nav_height + 16}px`;
      } else {
        ele.style.paddingTop = null;
      }
    })

    masterplan_inner.style.paddingTop = `${nav_height + 16}px`;
    masterplan_inner.style.paddingBottom = `${nav_height + 16}px`;
  }

  function backtopmonitor() {
    if (document.body.scrollTop > 800 || document.documentElement.scrollTop > 800) {
      document.querySelector('.back-to-top').classList.remove('hidden');
    } else {
      document.querySelector('.back-to-top').classList.add('hidden');
    }
  }

  function socialiconmonitor() {
    // let mq = window.matchMedia("(min-width: 1200px)");
    let mq = deviceWidth(767);

    if (mq) {
      document.querySelector('.social-container').style.background = null;
    } else {
      document.querySelector('.social-container').style.background = "#fff";
    }
  }

  document.querySelector('.social-register').addEventListener('click', (e) => {
    gsap.to(window, {
      duration: 1,
      scrollTo: e.currentTarget.dataset.target,
      ease: 'expo.inOut'
    });
  })

  document.querySelector('.back-to-top').addEventListener('click', (e) => {
    gsap.to(window, {
      duration: 1,
      scrollTo: 'body',
      ease: 'expo.inOut'
    });
  })

  // populate prefix on mobile phone field upon change select
  document.querySelector("#ffscountry").addEventListener('change', (e) => {
    const cur_prefix = e.target.options[e.target.selectedIndex].dataset.prefix;
    // console.log(e.target.options[e.target.selectedIndex].dataset.prefix);
    if (cur_prefix) {
      document.querySelector("#ffsmobileno").value = `${cur_prefix}`;
    } else {
      document.querySelector("#ffsmobileno").value = "";
    }
  })

  document.querySelector('#ffsacquisition_05').addEventListener('change', (e) => {
    let others_input = document.querySelector('#ffsacquisition_others');

    if(e.currentTarget.checked){
      others_input.removeAttribute('disabled');
    }else{
      others_input.setAttribute('disabled', true);
    }
  })

  document.querySelector('#ffsagent').addEventListener('change', (e)=>{
    let agent_field = document.querySelector('#ffsrenno');

    if(e.currentTarget.checked){
      $(agent_field.parentNode).fadeIn(200);
    }else{
      $(agent_field.parentNode).fadeOut(200);
    }
  })
}


/**
 * Enable mobile navigation.
 */
function enableMobileNavigation() {
  const button = document.getElementById('nav-icon');
  const container = document.getElementById('nav-mobile-menu-container');
  const menu = document.getElementById('nav-mobile-menu');

  $(button).on('click', function () {
    $(button).hasClass('open')
      ? hideMenu()
      : showMenu()
  });

  // $(menu).on('click', hideMenu)

  function showMenu() {
    // $(container).addClass('is-active');
    $(".navi-contain").addClass('is-active');
    $(button).addClass('open');
    // $('#nav-content').collapse('toggle');
  }

  function hideMenu() {
    $(".navi-contain").removeClass('is-active');
    $(button).removeClass('open');
    // $('#nav-content').collapse('toggle');
  }
}


/**
 * Enable fixed navigation.
 */
function enableFixedNavigation() {
  const hero = document.querySelector(`.js-page-hero`);
  const nav = document.getElementById('fixed-navigation');
  if (hero && nav) {
    const watcher = ScrollMonitor.create(hero);
    watcher.exitViewport(() => nav.classList.add('is-active'));
    watcher.enterViewport(() => nav.classList.remove('is-active'));
  }
}


/**
 * Activate smooth scrolling for anchor elements tagged with the
 * [data-smooth-scroll] attribute.
 */
function enableSmoothAnchorScrolling() {
  const moveTo = new MoveTo();
  $('[data-smooth-scroll]').on('click', function (e) {
    const url = new URL($(this)[0].href);
    const targetElement = $(url.hash)[0];
    if (targetElement) {
      e.preventDefault();
      moveTo.move(targetElement);
    }
  });
}


/**
 * Enable GA event tracking.
 */
function enableEventTracking() {
  foresight = new Foresight();
}


/**
 * Send an event to GA when a user fills in a form halfway then leaves the site.
 */
function trackFormAbandonment() {
  let isFormDirty = false;
  $('.registration-form input, .registration-form select, .registration-form textarea').change(() => {
    isFormDirty = true;
  });

  // Send GA event before browser closes tab
  window.addEventListener('beforeunload', () => {
    if (isFormDirty) {
      foresight.send({ category: 'General', action: 'Form Abandon' });
    }
  }, false);
}


/**
 * Enables the loading animation.
 */
function  enableLoadingAnimation() {
  onBegin();
  window.scrollTo(0, 0);

  function onBegin() {
    // $('#masthead').addClass('is-loading');
    // $('body').addClass('is-loading');

    $('#home-main-backgrounds').addClass('is-loaded');
    $('#home-hero-footer').addClass('is-loaded');

    document.querySelector('#ffsacquisition_05').checked = false;
    document.querySelector('#ffsagent').checked = false;

    // console.log(document.querySelector('#Group_46'));
    // console.log(document.querySelector('#Group_46').children)
    // const pavilion_array = Array.from(document.querySelector('#Group_46').children);
    // const genting_highlands = Array.from(document.querySelector('#Group_63').children);

    // pavilion_array.forEach(ele => {
    //   ele.classList.add('pavilion-animate');
    //   ele.style.opacity = '0';
    // })
    // genting_highlands.forEach(ele => {
    //   ele.classList.add('gh-animate');
    //   ele.style.opacity = '0';
    // })

    // document.querySelector('#Rectangle_9').style.transform = "scaleX(0)";

    // gsap.to('#Rectangle_9', {
    //   width: '100%',
    // })

    $('body').addClass('is-loaded').promise().done(() => {


      setTimeout(() => {
        // $('#Rectangle_9').addClass('triggered').promise().done(() => {
        //   setTimeout(() => {
        //     gsap.fromTo('.pavilion-animate',
        //       {
        //         autoAlpha: 0,
        //         y: '20px',
        //         onComplete: () => {
        //           gsap.fromTo('.gh-animate',
        //             {
        //               autoAlpha: 0,
        //               y: '20px',
        //               onComplete: () => {
        //                 gsap.fromTo('.fade-up', {
        //                   autoAlpha: 0,
        //                   y: '20px',
        //                 }, {
        //                   autoAlpha: 1,
        //                   y: 0,
        //                   duration: 1,
        //                   onComplete: () => {
        //                     setTimeout(() => {
        //                       animateSocial();
        //                     }, 500)
        //                   }
        //                 })
        //               }
        //             },
        //             {
        //               autoAlpha: 1,
        //               y: 0,
        //               duration: 1,
        //               stagger: 0.05
        //             })
        //         }
        //       },
        //       {
        //         autoAlpha: 1,
        //         y: 0,
        //         duration: 1,
        //         stagger: 0.05,

        //       })
        //   }, 1500)
        // })
        $('#masthead').removeClass('is-loading');
        $('#masthead').addClass('is-loaded').promise().done(() => {
          setTimeout(() => {
            gsap.fromTo('.fade-up', {
              autoAlpha: 0,
              y: '20px',
            }, {
              autoAlpha: 1,
              y: 0,
              duration: 1,
              onComplete: () => {
                setTimeout(() => {
                  animateSocial();
                }, 500)
              }
            })
          }, 1000)
        });
      }, 1000);
    })

    function animateSocial() {
      // deciding which animation to use
      // let mq = window.matchMedia("(min-width: 1200px)");
      let mq = deviceWidth(1200);

      let duration = 0.3;
      let stagger = 0.1;
      let ease = "power4";

      const timeline2 = gsap.timeline();

      // document.querySelectorAll('.social-icon').forEach((ele, i) => {

      if (!deviceWidth(767)) {
        timeline2.to('.social-container', {
          autoAlpha: 1,
          background: "#fff",
          duration
        })
      }

      if (mq) {
        timeline2.to(".social-icon", {
          autoAlpha: 1,
          x: 0,
          duration,
          // delay: () => {
          //   return i * stagger;
          // },
          stagger,
          ease
        })
      } else {
        timeline2
          .to(".social-icon", {
            autoAlpha: 1,
            y: 0,
            duration,
            stagger,
            delay: .5,
            ease
          })
      }

      // })

      // if (mq.matches) {
      //   gsap.to('.social-icon', {
      //     autoAlpha: 1,
      //     x: 0,
      //     duration,
      //     stagger,
      //     ease
      //   })
      // } else {
      //   gsap.to('.social-icon', {
      //     autoAlpha: 1,
      //     y: 0,
      //     duration,
      //     stagger,
      //     ease
      //   })
      // }
    }

    // if (document.readyState === 'complete') {
    //   window.setTimeout(onComplete, 500);
    // } else {
    //   window.addEventListener('load', onComplete);
    // }
  }

  function onComplete() {
    $('#masthead').removeClass('is-loading');
    $('#masthead').addClass('is-loaded');
    setTimeout(() => {
      $('body').addClass('is-loaded');
      $('body').removeClass('is-loading');
      $('#home-main-backgrounds').addClass('is-loaded');
      $('#home-hero-footer').addClass('is-loaded');
    }, 2000);
    // setTimeout(() => {
    //   $('#modal-season').modal('show');
    // }, 3000);
  }
}


/**
 * Enables the features carousel sliders and animations.
 */
function enableFeaturesCarousel() {
  $('#features-carousel').slick({
    appendDots: '#features-indicators',
    arrows: true,
    asNavFor: '#features-background-carousel',
    autoplaySpeed: 10000,
    dots: true,
    draggable: false,
    fade: true,
    nextArrow: document.getElementById('features-carousel-control-next'),
    prevArrow: document.getElementById('features-carousel-control-prev'),
    speed: 1200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          cssEase: 'ease',
        }
      }
    ]
  });

  // Start autoplay when in view
  const element = document.getElementById('features-carousel');
  if (element) {
    const watcher = ScrollMonitor.create(element);
    watcher.enterViewport(() => $('#features-carousel').slick('slickPlay'));
    watcher.exitViewport(() => $('#features-carousel').slick('slickPause'));
  }

  $('#features-background-carousel').slick({
    speed: 1200,
    arrows: false,
    fade: true,
    asNavFor: '#features-carousel',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          fade: false,
          draggable: true,
        }
      }
    ]
  });

  $('#features-carousel').on('afterChange', function (event, slick, currentSlide, nextSlide) {
    $('#features-carousel .feature-title-line').removeClass('animate');
    $('#features-carousel .slick-current .feature-title-line').addClass('animate');
  });
}


/**
 * Enable language.
 */
function enableLanguage() {
  const button = document.getElementsByClassName("btn-language");

  $(button).on('click', function () {
    $(button).removeClass("active");
    $(this).addClass("active");
  })
}

/**
 * Detect device width
 */
function deviceWidth(width) {
  let str = `(min-width: ${width}px)`;
  let mq = window.matchMedia(str);

  if (mq.matches) {
    return true;
  } else {
    return false;
  }
}
