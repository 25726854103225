const box_data = {
    box_1: {
        tagline: 'A REVITALISING SANCTUARY',
        tag: 'Live at Nature’s Pace',
        points: [
            'A wondrous haven, close to home.',
            'Just 35km away from Kuala Lumpur, spend leisurely days at Genting Highlands, a revitalising sanctuary surrounded by one of the oldest rainforests in the world.',
            'With an elevation between 650m and 1,860m above sea level, enjoy cooling temperatures from 20°C to 28°C all year round to rejuvenate the body and mind.'
        ]
    },
    box_2: {
        tagline: 'A GLOBAL DESTINATION',
        tag: 'An Integrated World-class Destination',
        points: [
            'After five decades since its inception in 1971, Resort Worlds Genting has cemented its role as a global lifestyle destination up in Genting Highlands, offering endless attractions and opportunities.',
            'Registering 28.7 million visitors in 2019 and an healthy annual growth rate of 9.2%, nothing is ordinary at this integrated world-class destination. This is the place to experience the best of leisure and entertainment, all on top of the world.'
        ]
    },
    box_3: {
        tagline: 'CAPTIVATING LIFESTYLE ATTRACTIONS',
        tag: 'Exciting Experiences at Genting Highlands',
        points: [
            'Allow Genting Highlands to elevate your holiday experience. Start off your holiday at Resorts World Genting, the place that has it all: from a casino, nightlife excitement, and extraordinary theme park attractions, to a plethora of dining options, you have arrived at the city of entertainment.',
            'Looking for more iconic destinations to visit? Continue the adventure by visiting the Chin Swee Caves temple, Awana Skyway, strawberry farm, orchid farm, and so much more. Fill your itinerary with these captivating activities that are suitable for all ages.'
        ]
    }
}

export default box_data;